import React from 'react'

import { PageScrollProvider } from './src/contexts/PageScroll'

import './src/styles/fonts.css'
import './src/styles/global.css'
import './src/styles/theme/theme.css'

export const wrapRootElement = ({ element }) => {
  return <PageScrollProvider>{element}</PageScrollProvider>
}
