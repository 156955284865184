import React, { createContext, useContext, useEffect, useState } from 'react'

import type { ReactNode } from 'react'

type PageScrollState = {
  scrollTop: number
}

const initialState: PageScrollState = {
  scrollTop: 0,
}

const PageScrollContext = createContext<PageScrollState>(initialState)

const PageScrollProvider = ({ children }: { children: ReactNode }) => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    if (!document) return

    const handlePageScroll = () => setScrollTop(window.scrollY)

    document.addEventListener('scroll', handlePageScroll)

    return () => document.removeEventListener('scroll', handlePageScroll)
  }, [])

  const value = {
    scrollTop,
  }

  return (
    <PageScrollContext.Provider value={value}>
      {children}
    </PageScrollContext.Provider>
  )
}

const usePageScroll = () => {
  const pageScrollState = useContext(PageScrollContext)

  return pageScrollState
}

export { PageScrollProvider, usePageScroll }
